import CategoryIcon from '@mui/icons-material/Bookmark';

import { CategoryList } from './CategoryList';
import { CategoryEdit } from './CategoryEdit';
import { CategoryCreate } from './CategoryCreate';

export default {
    list: CategoryList,
    edit: CategoryEdit,
    create: CategoryCreate,
    icon: CategoryIcon,
};
