// /src/hooks/useMessageSender.js
import { useCallback } from 'react';
import axios from 'axios';
import API_ROUTES from '../routes/routes'; 

const useMessageSender = () => {
    const sendMessage = useCallback(async (phoneNumber: string, message : string, isWhatsApp = false) => {

        const countryCode = process.env.REACT_APP_COUNTRY_CODE || ''; // Default to empty if not set
        const formattedPhoneNumber = `${countryCode}${phoneNumber.slice(-10)}`; // Assuming last 10 digits are the phone number

        const apiEndpoint = isWhatsApp 
            ? `${process.env.REACT_APP_API_URL}${API_ROUTES.messages}/sendWhatsApp` 
            : `${process.env.REACT_APP_API_URL}${API_ROUTES.messages}/sendSMS`;
        const payload = {
            to: formattedPhoneNumber,
            body: message
        };
        try {
            const response = await axios.post(apiEndpoint, payload);
            console.log('Message sent: ', response.data);
            return true;
        } catch (error) {
            console.error('Error sending message: ', error);
            return false;
        }
    }, []);

    return sendMessage;
};

export default useMessageSender;