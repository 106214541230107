import * as React from 'react';
import { FC, useState, Fragment } from 'react';
import {
    List, Datagrid,
    TextField, DateField, EditButton,
    useNotify, useDelete,
    ListProps, Button as RAButton,
    useRecordContext
} from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IOffer } from '../types/types'; // Adjust the path as needed
import useAuthorize from '../hooks/useAuthorize';
import { UserRole } from '../types/types';

const DeleteWithConfirmButton: FC = () => {
    const record = useRecordContext<IOffer>();
    const [deleteOne, { isLoading }] = useDelete();
    const [open, setOpen] = useState(false);
    const notify = useNotify();

    const handleDelete = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const confirmDelete = async () => {
        await deleteOne('offers', { id: record.id });
        notify('resources.offers.notification.deleted', {
            type: 'info',
            messageArgs: { smart_count: 1, name: 'Offer' }
        });
        setOpen(false);
    };

    return (
        <Fragment>
            <RAButton
                label="Delete"
                onClick={handleDelete}
                startIcon={<DeleteIcon />}
                disabled={isLoading}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this offer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={confirmDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export const OfferList: FC<ListProps> = (props) => {
    useAuthorize([UserRole.Admin]);
    return (
        <List {...props}>
            <Datagrid>
                <TextField source="post" label="Offer Details" />
                <DateField source="expirydate" label="Expiry Date" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};
