import React from 'react';
import { List, Datagrid, TextField, ReferenceInput, SelectInput, Toolbar } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack';
import useAdmin from '../hooks/useAdmin';

const LocationFilter = [
    <ReferenceInput source="city_id" reference="statecities/withstates" label="Location" alwaysOn>
        <SelectInput optionText="city_name" label="Delivery Location"/>
    </ReferenceInput>
];

const CustomToolbar = () => {
    const navigate = useNavigate();

    return (
        <Toolbar>
            <IconButton onClick={() => navigate('/products')}>
                <ArrowBack />
            </IconButton>
        </Toolbar>
    );
};

const ProductShippingList = () => {
    useAdmin();
    return (
        <List filters={LocationFilter} actions={<CustomToolbar />}
            sort={{ field: 'total_price', order: 'ASC' }}>
            <Datagrid>
                <TextField source="company_name" label="Seller" />
                <TextField source="price_date" label="Date" />
                <TextField source="city_name" label="Ware House" />
                <TextField source="price" label="Price" />
                <TextField source="commission" label="Commission" />
                <TextField source="shipping" label="Shipping" />
                <TextField source="total_price" label="Total" />
                <TextField source="total_price_without_commission" label="Total (exComm)" />
            </Datagrid>
        </List>
    );
};

export default ProductShippingList;
