import React, { FC, useState, Fragment } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    useNotify,
    useDelete,
    ListProps,
    Button as RAButton,
    useRecordContext
} from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IProductSegment } from '../types/types'; // Adjust the path as needed
import useAdmin from '../hooks/useAdmin';

const DeleteWithConfirmButton: FC = () => {
    const record = useRecordContext<IProductSegment>();
    const [deleteOne, { isLoading }] = useDelete();
    const [open, setOpen] = useState(false);
    const notify = useNotify();

    const handleDelete = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const confirmDelete = async () => {
        await deleteOne('productsegments', { id: record.id });
        notify('resources.productsegments.notification.deleted', {
            type: 'info',
            messageArgs: { smart_count: 1, name: 'Segment' }
        });
        setOpen(false);
    };

    return (
        <Fragment>
            <RAButton
                label="Delete"
                onClick={handleDelete}
                startIcon={<DeleteIcon />}
                disabled={isLoading}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this segment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={confirmDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export const ProductSegmentList: FC<ListProps> = (props) => {
    useAdmin();
    return (
        <List {...props}>
            <Datagrid>
                <TextField source="product_segment_name" label="Name"/>
                <TextField source="product_segment_description" label="Description"/>
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};
