import { ProductSegmentList } from './ProductSegmentList';
import { ProductSegmentEdit } from './ProductSegmentEdit';
import { ProductSegmentCreate } from './ProductSegmentCreate';
import ProductSegmentIcon from '@mui/icons-material/Bookmark';

export default {
    list: ProductSegmentList,
    edit: ProductSegmentEdit,
    create: ProductSegmentCreate,
    icon: ProductSegmentIcon,
};