import { useLocation, useNavigate } from 'react-router-dom';
import { UserRole } from '../types/types';

const useCheckLastPathParam = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userId = localStorage.getItem('user_id');
    const userRoleId = localStorage.getItem('user_role_id');
    const userRole = userRoleId ? parseInt(userRoleId) as UserRole : null;
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    if (lastSegment !== userId || userRole !== UserRole.Admin) {
        navigate('/not-authorized');
    }
};

export default useCheckLastPathParam;