import * as React from 'react';
import { List, Datagrid, TextField, NumberField, EditButton, DeleteButton, 
    useNotify, useRedirect, Button as RAButton, TopToolbar } from 'react-admin';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; 
import useAdmin from '../hooks/useAdmin';

export const OrderHistoryList = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const onSuccess = () => {
        notify('resources.orderhistory.notification.deleted', {
            type: 'info',
            undoable: true,
            messageArgs: { smart_count: 1, name: 'Order History' }
        });
    };
    const handleCreate = () => {
        const queryString = window.location.hash.split('?')[1] || '';
        const currentParams = new URLSearchParams(queryString);
        const currentFilterString = currentParams.get('filter');
        let orderId = '';
        if (currentFilterString) {
            const currentFilters = JSON.parse(decodeURIComponent(currentFilterString));
            if (currentFilters.order_id) {
                orderId = currentFilters.order_id.toString();
            }
        }
        if (orderId) {
            redirect(`/orderhistory/create?filter=${encodeURIComponent(JSON.stringify({ order_id: orderId }))}`);
        } else {
            redirect('/orderhistory/create');
        }
    };
    type ListActionsProps = {
        onAdd: () => void;
    };
    const handleBackToOrders = () => {
        redirect('/orders');
    };
    const ListActions = ({ onAdd }: ListActionsProps) => (
        <TopToolbar>
            <RAButton onClick={onAdd} label="Add" color="primary">
                <AddIcon />
            </RAButton>
            <RAButton onClick={handleBackToOrders} label="Back" color="primary">
                <ArrowBackIcon />
            </RAButton>
        </TopToolbar>
    );
    useAdmin();
    return (
        <List {...props} actions={<ListActions onAdd={handleCreate} />}>
            <Datagrid>
                <TextField source="order_id" label="Order Id" />
                <TextField source="order_sub_status" label="Status" />
                <TextField source="comment_date" label="Comment Date" />
                <TextField source="user_name" label="Commented By" />
                <TextField source="comments" label="Comment" />
                <EditButton />
                <DeleteButton mutationOptions={{ onSuccess }} />
            </Datagrid>
        </List>
    );
};

export default OrderHistoryList;