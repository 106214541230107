import * as React from 'react';
import { useState, Fragment } from 'react';
import format from 'date-fns/format';
import { 
    List, 
    Datagrid, 
    TextField, 
    FunctionField, 
    BooleanField, 
    TopToolbar, 
    useNotify, 
    useDeleteMany, 
    useUnselectAll 
} from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import ArrowBack from '@mui/icons-material/ArrowBack';  // Correct import for ArrowBack
import { useNavigate } from 'react-router-dom';
import useAdmin from '../hooks/useAdmin';
import { UserRole } from '../types/types'; // Ensure this import is correct

interface ProductPricingRecord {
    [key: string]: any; // Index signature
    id: number;
    product_id: number;
    user_id: number;
    price_date: Date;
    price: number;
    company_name: string;
}

const CustomDateField: React.FC<{ record?: ProductPricingRecord, source: string }> = ({ record, source }) => {
    return record && record[source] ? (
        <span>{format(new Date(record[source]), 'dd-MM-yyyy HH:mm')}</span>
    ) : null;
};

const CustomToolbar: React.FC = () => {
    const navigate = useNavigate();
    useAdmin();
    return (
        <TopToolbar>
            <IconButton onClick={() => navigate('/products')}>
                <ArrowBack />
            </IconButton>
        </TopToolbar>
    );
};

const BulkDeleteWithConfirmButton: React.FC<any> = (props) => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const [deleteMany, { isLoading }] = useDeleteMany();
    const unselectAll = useUnselectAll('productHistory');

    const role_id = localStorage.getItem('user_role_id');
    const userRole = role_id ? parseInt(role_id) as UserRole : null;

    const handleOpen = () => {
        // Check if the user is an admin
        if (userRole === UserRole.Admin) {
            setOpen(true);
        } else {
            alert('Not Authorized. Contact your Administrator');
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        deleteMany('productHistory', { ids: props.selectedIds }).then(() => {
            notify('Products deleted successfully', { type: 'info' });
            unselectAll();
            setOpen(false);
        }).catch((error) => {
            notify(`Error: ${error.message}`, { type: 'warning' });
        });
    };

    return (
        <Fragment>
            <Button
                onClick={handleOpen}
                startIcon={<DeleteIcon />}
                disabled={isLoading || props.selectedIds.length === 0}
                color="error"
            >
                Delete
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete the selected records? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleDelete} color="secondary">Delete</Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

const ProductHistory: React.FC<any> = (props) => {
    return (
        <List {...props} actions={<CustomToolbar />} bulkActionButtons={<BulkDeleteWithConfirmButton />} sort={{ field: 'price_date', order: 'DESC' }}>
            <Datagrid>
                <TextField source="company_name" label="Seller" />
                <TextField source="city_name" label="Ware House" />
                <TextField source="price" label="Price" />
                <FunctionField source="price_date" label="Price Update Date" render={(record: ProductPricingRecord) => (
                    <CustomDateField record={record} source="price_date" />
                )} />
                <BooleanField source="is_lowest" label="Is Lowest" />
            </Datagrid>
        </List>
    );
};

export default ProductHistory;
