// /Users/gaurav/Dropbox/Projects/CredCoApp/Admin/CredCo/client/src/stateCities/index.ts
import CityIcon from '@mui/icons-material/LocationCity';

import { StateCityList } from './StateCityList';
import { StateCityEdit } from './StateCityEdit';
import { StateCityCreate } from './StateCityCreate';

export default {
    list: StateCityList,
    edit: StateCityEdit,
    create: StateCityCreate,
    icon: CityIcon,
};
