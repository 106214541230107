// /Users/gaurav/Dropbox/Projects/CredCoApp/Admin/CredCo/client/src/hooks/useCheckQueryParam.ts

import { useNavigate } from 'react-router-dom';
import { UserRole } from '../types/types';

const useCheckQueryParam = (paramValueToCheck: string) => {
    const navigate = useNavigate();
    const userId = localStorage.getItem('user_id');
    const userRoleId = localStorage.getItem('user_role_id');
    const userRole = userRoleId ? parseInt(userRoleId) as UserRole : null;

        // Allow unrestricted access for Admin and ProductAdmin
    if (userRole === UserRole.Admin || userRole === UserRole.ProductAdmin) {
        return; // No action needed, access allowed
    }

    // For other roles, check if the paramValueToCheck matches the userId
    if (paramValueToCheck !== userId) {
        navigate('/not-authorized');
    }
};

export default useCheckQueryParam;
