import * as React from 'react';
import { Create, SimpleForm, TextInput, NumberInput, required, maxLength, 
    Toolbar, SaveButton, useNotify, useRedirect, ReferenceInput,SelectInput,useRecordContext,FormDataConsumer  } from 'react-admin';
import { Button } from '@mui/material';
import axios from 'axios';
import API_ROUTES from '../routes/routes'; 
import useMessageSender from '../hooks/useMessageSender';
import useAdmin from '../hooks/useAdmin';

export const OrderHistoryCreate = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const currentDateTime = new Date().toISOString();
    const currentUserId =  localStorage.getItem('user_id');
    const sendMessage = useMessageSender();

    const queryString = window.location.hash.split('?')[1] || '';
    const currentParams = new URLSearchParams(queryString);
    const currentFilterString = currentParams.get('filter');
    let orderId = '';
    if (currentFilterString) {
        const currentFilters = JSON.parse(decodeURIComponent(currentFilterString));
        if (currentFilters.order_id) {
            orderId = currentFilters.order_id.toString();
        }
    }
    const defaultValues = { order_id: orderId } as any;

    const onSuccess = async (response: any) => {
        console.log("response", response);
        notify('resources.orderhistory.notification.created', {
            type: 'info',
            messageArgs: { smart_count: 1, name: 'Order History' }
        });

        try {
            const orderHistoryResponse = await axios.get(`${process.env.REACT_APP_API_URL}${API_ROUTES.orderhistory}/${response.id}`);
            const appName = process.env.REACT_APP_APP_NAME;
            const message = `Your Order Id ${ orderHistoryResponse.data.order_id} with ${appName} is updated - ${orderHistoryResponse.data.order_sub_status} : ${orderHistoryResponse.data.comments}`;
            if (process.env.REACT_APP_SMS_ENABLED === 'true') {
                sendMessage(orderHistoryResponse.data.phone_mobile, message, false); // Set to true to send as WhatsApp message
            }
        } catch (error) {
            console.error('Error fetching order history details:', error);
        }

        const customUrl = `/orderhistory?filter=${encodeURIComponent(JSON.stringify({ order_id: orderId }))}`;
        redirect(customUrl);
    };

    const handleBack = () => {
        const customUrl = `/orderhistory?filter=${encodeURIComponent(JSON.stringify({ order_id: orderId }))}`;
        redirect(customUrl);
    };

    const CustomToolbar = () => {
        const redirect = useRedirect();
        const recordContext = useRecordContext();
        return (
            <Toolbar>
                <SaveButton />
                <Button color="primary" onClick={handleBack} style={{ marginLeft: '10px' }}>Back</Button>
            </Toolbar>
        );
    };

    const [formValues, setFormValues] = React.useState({ order_id: '' });
    React.useEffect(() => {
        if (orderId) {
            setFormValues({ order_id: orderId });
        }
    }, [orderId]);
    useAdmin();
    return (
        <Create {...props} mutationOptions={{ onSuccess: onSuccess }}>
                <SimpleForm toolbar={<CustomToolbar />}>
                <TextInput source="order_id" label="Order Id" validate={[required(), maxLength(50)]} defaultValue={orderId} disabled/>
                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        <React.Fragment>
                            <TextInput source="comment_date" label="Comment Date" defaultValue={currentDateTime} style={{ display: 'none' }}/>
                            <NumberInput source="comment_user_id" label="User ID" defaultValue={currentUserId} style={{ display: 'none' }}/>
                        </React.Fragment>
                    )}
                </FormDataConsumer>
                <ReferenceInput source="order_sub_status_id" reference="orderhistory/getOrderSubStatus" label="Status">
                    <SelectInput optionText="order_sub_status" validate={[required()]}/>
                </ReferenceInput>
                <TextInput source="comments" label="Comments" multiline fullWidth validate={maxLength(300)}/>
            </SimpleForm>
        </Create>
    );
};

export default OrderHistoryCreate;
