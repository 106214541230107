import React, { useState, useEffect, CSSProperties } from 'react';
import MonthlyOrders from './MonthlyOrders';
import PendingOrders from './PendingOrders';
import NewCustomers from './NewCustomers';
import UpdatedProducts from './UpdatedProducts';
import axios from 'axios';
import API_ROUTES from '../routes/routes'; 
import { UserRole } from '../types/types'; 

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;

const Dashboard = () => {
    const [data, setData] = useState({ pending_orders: 0, recent_customers: 0, recent_products: 0, monthly_orders: 0 });
    const [userRole, setUserRole] = useState<UserRole | null>(null);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}${API_ROUTES.dashboard}`)
            .then(response => {
                setData(response.data[0]);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, []);

    useEffect(() => {
        const role_id = localStorage.getItem('user_role_id');
        const role = role_id ? parseInt(role_id) as UserRole : null;
        setUserRole(role);
    }, []);

    if (userRole !== UserRole.Admin) {
        return <></>; // Or some fallback UI
    }

    return (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.singleCol}></div>
            <div style={styles.flex}>
                <MonthlyOrders value={data.monthly_orders} />
                <Spacer />
                <PendingOrders value={data.pending_orders} />
                <Spacer />
                <NewCustomers value={data.recent_customers} />
                <Spacer />
                <UpdatedProducts value={data.recent_products} />
            </div>
        </div>
    );
};

export default Dashboard;
