import * as React from 'react';
import { FC } from 'react';
import { useTheme } from '@mui/material/styles';

type LogoProps = {
    // Define your props here, for example:
    // size: string;
};
const Logo: FC<LogoProps> = (props) => {
    const theme = useTheme();
    return (
        <img
            src="/logo.png" // Relative path from Logo.tsx to logo.png
            alt="Credco"
            style={{
                width: '140px', // Adjust the width as needed
                height: '24px', // Adjust the height as needed
                color: theme.palette.secondary.light, // If you need to apply theme color
            }}
            {...props}
        />
    );
};

export default Logo;
