import { SubCategoryList } from './SubCategoryList';
import { SubCategoryEdit } from './SubCategoryEdit';
import { SubCategoryCreate } from './SubCategoryCreate';
import SubCategoryIcon from '@mui/icons-material/Bookmark';

export default {
    list: SubCategoryList,
    edit: SubCategoryEdit,
    create: SubCategoryCreate,
    icon: SubCategoryIcon,
};