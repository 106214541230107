import ProductIcon from '@mui/icons-material/Bookmark';

import { ProductList } from './ProductList';
import { ProductEdit } from './ProductEdit';
import  ProductCreate  from './ProductCreate';

export default {
    list: ProductList,
    edit: ProductEdit,
    create: ProductCreate,
    icon: ProductIcon,
};
