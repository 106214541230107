import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../types/types';

const useAdmin = () => {
    const navigate = useNavigate();
    const role_id = localStorage.getItem('user_role_id');
    const userRole = role_id ? parseInt(role_id) as UserRole : null;

    useEffect(() => {
        if (userRole !== UserRole.Admin) {
            navigate('/not-authorized'); // Redirect to 'Not Authorized' page
        }
    }, [userRole, navigate]);
};

export default useAdmin;
