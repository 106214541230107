import React from 'react';
import { Edit } from 'react-admin';
import { UserForm } from './UserForm';
import useCheckLastPathParam from '../hooks/useCheckLastPathParam';

export const UserEdit  = (props: any) => {
    useCheckLastPathParam();
    return (
        <Edit title="Edit User" {...props}>
            <UserForm isEdit={true} />
        </Edit>
    );
};

export default UserEdit;
