import * as React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, required, maxLength, Toolbar, SaveButton, 
    useNotify, useRedirect, ReferenceInput, SelectInput, FormDataConsumer, useRecordContext } from 'react-admin';
import { Button } from '@mui/material';
import useAdmin from '../hooks/useAdmin';

const CustomToolbar = () => {
    const redirect = useRedirect();
    const recordContext = useRecordContext();

    const handleBack = () => {
        if (recordContext && recordContext.order_id) {
            const customUrl = `/orderhistory?filter=${encodeURIComponent(JSON.stringify({ order_id: recordContext.order_id }))}`;
            redirect(customUrl);
        } else {
            redirect('/orderhistory');
        }
    };

    return (
        <Toolbar>
            <SaveButton />
            <Button color="primary" onClick={handleBack} style={{ marginLeft: '10px' }}>Back</Button>
        </Toolbar>
    );
};

export const OrderHistoryEdit = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [currentOrderId, setCurrentOrderId] = React.useState('');

    const onSuccess = () => {
        notify('resources.orderhistory.notification.updated', {
            type: 'info',
            undoable: true,
            messageArgs: { smart_count: 1, name: 'Order History' }
        });
        const customUrl = `/orderhistory?filter=${encodeURIComponent(JSON.stringify({ order_id: currentOrderId }))}`;
        redirect(customUrl);

    };  
    useAdmin();
    return (
        <Edit {...props} mutationOptions={{ onSuccess: onSuccess }}>
            <SimpleForm toolbar={<CustomToolbar />}>
            <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        if (formData.order_id && formData.order_id !== currentOrderId) {
                            setCurrentOrderId(formData.order_id);
                        }
                        return null; // No need to render anything
                    }}
                </FormDataConsumer>

                <TextInput source="order_id" label="Order Id" validate={[required(), maxLength(50)]} disabled/>
                <ReferenceInput source="order_sub_status_id" reference="orderhistory/getOrderSubStatus" label="Status">
                    <SelectInput optionText="order_sub_status" validate={[required()]}/>
                </ReferenceInput>
                <TextInput source="comments" label="Comments" multiline fullWidth validate={maxLength(300)}/>
            </SimpleForm>
        </Edit>
    );
};

export default OrderHistoryEdit;