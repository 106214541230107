import React, { FC,useEffect, useState } from 'react';

import axios from 'axios';
import { List, Datagrid, TextField, FunctionField, DateField, NumberField,TextInput, ListProps } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import useAuthorize from '../hooks/useAuthorize';
import { UserRole } from '../types/types';

interface ISeller {
    id: number;
    company_name: string;
    active_products: number;
    max_price_date: string;
    city_count: number;
    top_city_id: number;
    // Add other seller properties here as needed
}

interface ProductPricingFieldProps {
    record: ISeller;
}

const SellerFilter = [
    <TextInput label="Seller" source="seller_search" resettable alwaysOn/>,
];

const SellerFilterList: FC<ListProps> = (props) => {
    const [sellers, setSellers] = useState<ISeller[]>([]);
    const navigate = useNavigate();

    const CompanyNameField = ({ record }: ProductPricingFieldProps) => (
        <a href="#" onClick={(e) => {
            e.preventDefault();
            navigate(`/productpricing?filter=${encodeURIComponent(JSON.stringify({ seller_id: record.id, city_id: record.top_city_id }))}`);
            //navigate(`/productpricing?filter=${encodeURIComponent(JSON.stringify({ id: record.id }))}`);
        }}>
            {record.company_name}
        </a>
    );
    useAuthorize([UserRole.Admin, UserRole.ProductAdmin]);
    return (
        <List filters={SellerFilter} {...props}>
            <Datagrid isLoading={!sellers}>
                <FunctionField label="Seller Name (Select for Pricing)" render={(record: ISeller) => <CompanyNameField record={record} />} sortable source="company_name" />
                <TextField source="active_products" label="Active Products" sortable />
                <NumberField source="city_count" label="Active Warehouse" sortable />
                <DateField source="max_price_date" label="Last Active" showTime />
            </Datagrid>
        </List>
    );
};

export default SellerFilterList;
