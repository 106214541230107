import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../types/types';
import authProvider from '../auth/authProvider';

const useAuthorize = (allowedRoles: UserRole[]) => {
    const navigate = useNavigate();


    useEffect(() => {
        if (!authProvider.isRoleAuthorized(allowedRoles)) {
            navigate('/not-authorized');
        }
    }, [allowedRoles, navigate]);
};

export default useAuthorize;