import * as React from 'react';
import { useEffect, useState } from 'react';
import {
    Edit,SimpleForm,TextInput,ReferenceInput,SelectInput,
    useDataProvider,FormDataConsumer,useNotify,useRedirect,required,maxLength,Toolbar, SaveButton
} from 'react-admin';
import { ISubCategory } from '../types/types';
import { Button } from '@mui/material';
import useAdmin from '../hooks/useAdmin';

const validateDecimal = (message: string) => (value: any) => {
    if (!value) return; // Skip validation if value is empty
    const regex = /^\d+(\.\d{1,2})?$/; // Regex for validating decimal with two places
    return regex.test(value) ? undefined : message;
};

const SubCategorySelectInput = ({ categoryId, subCategoryId }: { categoryId: number, subCategoryId: number }) => {
    const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (categoryId) {
            dataProvider.getList('subcategories/bycategory', {
                filter: { category_id: categoryId },
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'sub_category_name', order: 'ASC' }
            }).then(({ data }) => setSubCategories(data));
        } else {
            setSubCategories([]);
        }
    }, [categoryId, dataProvider]);

    return (
        <SelectInput
            source="sub_category_id"
            label="Sub Category"
            choices={subCategories}
            optionText="sub_category_name"
            optionValue="id"
            defaultValue={subCategoryId}
        />
    );
};

const CustomToolbar = () => {
    const redirect = useRedirect();

    const handleBack = () => {
        redirect('/products');
    };

    return (
        <Toolbar>
            <SaveButton />
            <Button color="primary" onClick={handleBack} style={{ marginLeft: '10px' }}>Back</Button>
        </Toolbar>
    );
};
export const ProductEdit = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.products.notification.updated', {
            type: 'info',
            undoable: true,
            messageArgs: { smart_count: 1, name: 'Product' }
        });
        redirect('/products');
    };

    const [selectedCategoryId, setSelectedCategoryId] = useState<number | undefined>();

    useEffect(() => {
        if (props.record && props.record.sub_category_id) {
            setSelectedCategoryId(props.record.category_id);
        }
    }, [props.record]);
    useAdmin();
    return (
        <Edit {...props}  mutationOptions={{onSuccess: onSuccess}}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <ReferenceInput source="category_id" reference="categories" label="Category" onChange={(e) => setSelectedCategoryId(e.target.value)}>
                    <SelectInput optionText="category_name" validate={[required()]}/>
                </ReferenceInput>

                <FormDataConsumer>
                    {({ formData, ...rest }) => (
                        formData.category_id ? 
                            <SubCategorySelectInput categoryId={formData.category_id} subCategoryId={formData.sub_category_id} {...rest} /> 
                            : null
                    )}
                </FormDataConsumer>

                <TextInput source="product_name" label="Description"  validate={[required(), maxLength(20)]}/>
                <TextInput source="product_grade" label="Grade" validate={[required(), maxLength(30)]}/>
                <TextInput source="product_mfi" label="MFI" />
                <TextInput source="product_commission" label="Commission" validate={[required(), validateDecimal('Must be a valid price.')]} />

            </SimpleForm>
        </Edit>
    );
};

export default ProductEdit;
