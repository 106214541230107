// client/src/orders/index.ts
import { OrderList } from './OrderList';
import { OrderEdit } from './OrderEdit';
import { OrderCreate } from './OrderCreate';
import OrderIcon from '@mui/icons-material/ListAlt';

export default {
    list: OrderList,
    edit: OrderEdit,
    icon: OrderIcon,
};
