import OrderHistoryIcon from '@mui/icons-material/History';

import { OrderHistoryList } from './OrderHistoryList';
import { OrderHistoryEdit } from './OrderHistoryEdit';
import { OrderHistoryCreate } from './OrderHistoryCreate';

export default {
    list: OrderHistoryList,
    edit: OrderHistoryEdit,
    create: OrderHistoryCreate,
    icon: OrderHistoryIcon,
};
