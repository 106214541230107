import { 
  Admin, 
  Resource,
  CustomRoutes, 
  localStorageStore,
  useStore,
  StoreContextProvider
} from 'react-admin';

import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from './i18n/en';
import hindiMessages from './i18n/en';
import { Route } from 'react-router';
import jsonServerProvider from 'ra-data-json-server';
import Segments from './segments/Segments';
import authProvider from './auth/authProvider';
//import { themes, ThemeName } from './themes/themes';
import { Layout, Login } from './layout';
import categories from './categories';
import subCategories from './subCategories';
import productSegments from './productSegments';
import products from './products';
import users from './users';
import customers from './customers';
import productPricing from './productPricing';
import SellerFilterList from './sellerFilter/SellerFilterList';
import ProductShippingList from './productShipping/ProductShippingList';
import ProductHistory  from './productHistory/productHistory';
import OrderHistory from './orderHistory'
import states from './states';
import stateCities from './stateCities';
import cityShipping from './cityShipping';
import orders from './orders';
import { Dashboard } from './dashboard';
import NotAuthorized from './notAuthorized';
import unregistered from "./unregistered"
import offers from './offers';


const i18nProvider = polyglotI18nProvider(
  locale => {
      if (locale === 'hi') {
        return import('./i18n/hi').then(messages => messages.default);
    }
      // Always fallback on english
      return englishMessages;
  },
  'en',
  [
      { locale: 'en', name: 'English' },
      { locale: 'hi', name: 'हिंदी' },
  ]
);

const store = localStorageStore(process.env.CURRENT_VERSION, process.env.APP_MODULE);

const App = () => {
    //const [themeName] = useStore<ThemeName>('themeName', 'soft');
    //const lightTheme = themes.find(theme => theme.name === themeName)?.light;
    //const darkTheme = themes.find(theme => theme.name === themeName)?.dark;
    const dataProvider = jsonServerProvider(process.env.REACT_APP_API_URL); // Use your API's base URL
    return (
      <Admin 
        store={store}
        dataProvider={dataProvider}
        layout={Layout}
        i18nProvider={i18nProvider}
        authProvider={authProvider}
        loginPage={Login}
        dashboard={Dashboard}
        //lightTheme={lightTheme}
        //darkTheme={darkTheme}
        //defaultTheme="light"
      >
        <CustomRoutes>
          <Route path="/segments" element={<Segments />} />
          <Route path="/not-authorized" element={<NotAuthorized />} />
        </CustomRoutes>
        <Resource name="categories" {...categories} />
        <Resource name="subcategories" {...subCategories} />
        <Resource name="productsegments" {...productSegments} />
        <Resource name="products" {...products} />
        <Resource name="productpricing" {...productPricing} />
        <Resource name="users" {...users} />
        <Resource name="customers" {...customers} />
        <Resource name="sellerFilter"  list={SellerFilterList} />
        <Resource name="productHistory" list={ProductHistory} />
        <Resource name="states" {...states} />
        <Resource name="statecities" {...stateCities} />
        <Resource name="cityshipping" {...cityShipping} />
        <Resource name="productShipping"  list={ProductShippingList} />
        <Resource name="orders" {...orders} />
        <Resource name="orderhistory" {...OrderHistory} />
        <Resource name="unregistered" {...unregistered} />
        <Resource name="offers" {...offers} />

     </Admin>
    );
};

const AppWrapper = () => (
    <StoreContextProvider value={store}>
        <App />
    </StoreContextProvider>
);

export default AppWrapper;
