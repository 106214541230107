import * as React from 'react';
import { Edit, SimpleForm, ReferenceInput, SelectInput, NumberInput, required, minValue, Toolbar, SaveButton, useNotify, useRedirect } from 'react-admin';
import { Button } from '@mui/material';
import useAdmin from '../hooks/useAdmin';

const CustomToolbar = () => {
    const redirect = useRedirect();

    const handleBack = () => {
        redirect('/cityshipping');
    };

    return (
        <Toolbar>
            <SaveButton />
            <Button color="primary" onClick={handleBack} style={{ marginLeft: '10px' }}>Back</Button>
        </Toolbar>
    );
};

export const CityShippingEdit = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.cityshipping.notification.updated', {
            type: 'info',
            undoable: true,
            messageArgs: { smart_count: 1, name: 'City Shipping' }
        });
        redirect('/cityshipping');
    };
    useAdmin();
    return (
        <Edit {...props} mutationOptions={{onSuccess: onSuccess}}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <ReferenceInput source="city_from" reference="statecities/withstates" alwaysOn>
                    <SelectInput optionText="city_name" label="City From" validate={[required()]}/>
                </ReferenceInput>
                <ReferenceInput source="city_to" reference="statecities/withstates" alwaysOn>
                    <SelectInput optionText="city_name" label="City To" validate={[required()]}/>
                </ReferenceInput>
                <NumberInput source="shipping_charge" label="Shipping Charge" validate={[required(), minValue(0)]}/>
            </SimpleForm>
        </Edit>
    );
};

export default CityShippingEdit;
