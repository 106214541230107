import * as React from 'react';
import { Edit, SimpleForm, ReferenceInput, SelectInput, TextInput, Toolbar, SaveButton, required, useNotify, useRedirect } from 'react-admin';
import { Button } from '@mui/material';
import useAdmin from '../hooks/useAdmin';

const CustomToolbar = () => {
    const redirect = useRedirect();

    const handleBack = () => {
        redirect('/subcategories');
    };

    return (
        <Toolbar>
            <SaveButton />
            <Button color="primary" onClick={handleBack} style={{ marginLeft: '10px' }}>Back</Button>
        </Toolbar>
    );
};

export const SubCategoryEdit = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.subcategories.notification.updated', {
            type: 'info',
            undoable: true,
            messageArgs: { smart_count: 1, name: 'SubCategory' }
        });
        redirect('/subcategories');
    };
    useAdmin();
    return (
        <Edit {...props} mutationOptions={{ onSuccess: onSuccess }}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <ReferenceInput source="parent_id" reference="categories" label="Category">
                    <SelectInput optionText="category_name" validate={[required()]}/>
                </ReferenceInput>
                <TextInput source="sub_category_name" label="Sub Category"/>
                <TextInput source="sub_category_description" label="Description"/>
            </SimpleForm>
        </Edit>
     );
};
