import React from 'react';
import { Create, SimpleForm, ReferenceInput, SelectInput, TextInput, required, maxLength, Toolbar, SaveButton, useNotify, useRedirect } from 'react-admin';
import { Button } from '@mui/material';
import useAdmin from '../hooks/useAdmin';

// Custom validation function
const validateCategorySelection = (value: any) => {
    return value ? undefined : 'Select a category';
};

const CustomToolbar = () => {
    const redirect = useRedirect();
    const handleBack = () => {
        redirect('/subcategories');
    };
    return (
        <Toolbar>
            <SaveButton />
            <Button color="primary" onClick={handleBack} style={{ marginLeft: '10px' }}>Back</Button>
        </Toolbar>
    );
};

export const SubCategoryCreate = (props: any) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const onSuccess = () => {
        notify('resources.subcategories.notification.created', {
            type: 'info',
            messageArgs: { smart_count: 1, name: 'SubCategory' },
        });
        redirect('/subcategories');
    };
    useAdmin();
    return (
        <Create {...props} mutationOptions={{ onSuccess: onSuccess }}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <ReferenceInput
                    source="parent_id"
                    reference="categories"
                    label="Category"
                >
                    <SelectInput optionText="category_name" validate={[required()]}/>
                </ReferenceInput>
                <TextInput
                    source="sub_category_name"
                    label="Sub Category"
                    validate={[required(), maxLength(20)]}
                />
                <TextInput
                    source="sub_category_description"
                    label="Description"
                    validate={maxLength(50)}
                />
            </SimpleForm>
        </Create>
    );
};

export default SubCategoryCreate;
