import * as React from 'react';
import DollarIcon from '@mui/icons-material/AttachMoney';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: number;
}

const MonthlyOrder = (props: Props) => {
    const { value } = props;
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/orders"
            icon={DollarIcon}
            title={'Monthly Orders'}
            subtitle={value}
        />
    );
};

export default MonthlyOrder;