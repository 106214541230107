import * as React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: number;
}

const PendingOrders = (props: Props) => {
    const { value } = props;
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/products"
            icon={CheckCircleIcon}
            title={'Product Updated'}
            subtitle={value}
        />
    );
};

export default PendingOrders;