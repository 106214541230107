import React, { FC } from 'react';
import { List, Datagrid, TextField, TextInput, ListProps, SortPayload } from 'react-admin';
import useAdmin from '../hooks/useAdmin';

const UnregisteredFilter = [
    <TextInput label="Mobile Number" source="unregistered_search" resettable alwaysOn key="unregistered_search" />,
];

export const UnregisteredList: FC<ListProps> = (props) => {
    useAdmin();
    
    // Define the default sort order with explicit types
    const defaultSort: SortPayload = { field: 'request_date_time', order: 'DESC' };

    return (
        <List filters={UnregisteredFilter} sort={defaultSort} {...props}>
            <Datagrid>
                <TextField source="mobile_number" label="Mobile Number" />
                <TextField source="request_date_time" label="Login Date" />
            </Datagrid>
        </List>
    );
};

export default UnregisteredList;
