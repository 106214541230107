import axios from 'axios';
import { AuthProvider } from 'react-admin';
import API_ROUTES from '../routes/routes'; 
import { UserIdentity, UserRole } from '../types/types'; 

const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
        // Clear any existing session before attempting a new login
        localStorage.clear();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}${API_ROUTES.login}`, { username, password });
            const { token, user } = response.data;
            localStorage.setItem('user_authToken', token);
            localStorage.setItem('user_id', user.id);
            localStorage.setItem('user_name', user.user_name);
            localStorage.setItem('user_fullName', `${user.user_first_name} ${user.user_last_name}`);
            localStorage.setItem('user_role_id', user.role_id);
            localStorage.setItem('user_role', user.role);

        } catch (error) {
            console.error('Login error:', error);
            throw new Error('Login failed');
        }
    },

    logout: () => {
        localStorage.clear();
        return Promise.resolve();
    },
    checkError: (error) => {
        if (error.status === 401 || error.status === 403) {
            localStorage.clear();
            return Promise.reject();
        }
        if (!error.response) { 
            console.error('Network error:', error);
            return Promise.resolve(); // to not log out
        }
        return Promise.resolve();
    },    
    checkAuth: () =>
        localStorage.getItem('user_authToken') ? Promise.resolve() : Promise.reject(),
    getPermissions: () => {
        const role = localStorage.getItem('user_role');
        return Promise.resolve(role);
    },
    getIdentity: () => {
        const id = localStorage.getItem('user_id') || '';
        const userName = localStorage.getItem('user_name') || '';
        const fullName = localStorage.getItem('user_fullName') || '';
        const role_id = localStorage.getItem('user_role_id') || '';
        const defaultRoleId = UserRole.Admin; 
        const convertedRoleId = role_id ? parseInt(role_id) as UserRole : defaultRoleId;
        const role = localStorage.getItem('user_role') || '';
        const identity: UserIdentity = { id, fullName, role_id: convertedRoleId, role, avatar: '' }; // Add avatar if available
        return Promise.resolve(identity);

    },
};
const getUserRole = (): UserRole | null => {
    const role_id = localStorage.getItem('user_role_id');
    return role_id ? parseInt(role_id) as UserRole : null;
};

const isRoleAuthorized = (allowedRoles: UserRole[]): boolean => {
    const userRole = getUserRole();
    return userRole ? allowedRoles.includes(userRole) : false;
};

authProvider.getUserRole = getUserRole;
authProvider.isRoleAuthorized = isRoleAuthorized;

export default authProvider;