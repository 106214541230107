import CustomerIcon from '@mui/icons-material/Group'; // Use appropriate icon

import CustomerList from './CustomerList';
import CustomerCreate from './CustomerCreate';
import CustomerEdit from './CustomerEdit';

export default {
    list: CustomerList,
    create: CustomerCreate,
    edit: CustomerEdit,
    icon: CustomerIcon,
};