import React, { FC, useState, Fragment } from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    EditButton,
    useNotify,
    useDelete,
    ListProps,
    Button as RAButton,
    useRecordContext,
    TextInput
} from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ICityShipping } from '../types/types'; // Adjust the path as needed
import useAdmin from '../hooks/useAdmin';

const DeleteWithConfirmButton: FC = () => {
    const record = useRecordContext<ICityShipping>();
    const [deleteOne, { isLoading }] = useDelete();
    const [open, setOpen] = useState(false);
    const notify = useNotify();

    const handleDelete = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const confirmDelete = async () => {
        await deleteOne('cityshipping', { id: record.id });
        notify('resources.cityshipping.notification.deleted', {
            type: 'info',
            messageArgs: { smart_count: 1, name: 'City Shipping' }
        });
        setOpen(false);
    };

    return (
        <Fragment>
            <RAButton
                label="Delete"
                onClick={handleDelete}
                startIcon={<DeleteIcon />}
                disabled={isLoading}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this city shipping record?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={confirmDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

const ShippingFilter = [
    <TextInput label="From City" source="city_from" resettable alwaysOn/>,
    <TextInput label="To City" source="city_to" resettable alwaysOn/>,
];

export const CityShippingList: FC<ListProps> = (props) => {
    useAdmin();
    return (
        <List filters={ShippingFilter} {...props}>
            <Datagrid>
                <TextField source="city_from_name" label="From City" />
                <TextField source="city_to_name" label="To City" />
                <NumberField source="shipping_charge" options={{ style: 'currency', currency: 'INR' }} />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};
