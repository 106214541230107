import * as React from 'react';
import { FC, useState, Fragment } from 'react';
import {
    List,Datagrid,TextField,EditButton,useNotify,
    useDelete,ListProps,Button as RAButton,
    useRecordContext, ReferenceField
} from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ISubCategory } from '../types/types';
import useAdmin from '../hooks/useAdmin';

const DeleteWithConfirmButton: FC = () => {
    const record = useRecordContext<ISubCategory>();
    const [deleteOne, { isLoading }] = useDelete();
    const [open, setOpen] = useState(false);
    const notify = useNotify();

    const handleDelete = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const confirmDelete = async () => {
        await deleteOne('subcategories', { id: record.id });
        notify('resources.subcategories.notification.deleted', {
            type: 'info',
            messageArgs: { smart_count: 1, name: 'SubCategory' }
        });
        setOpen(false);
    };
    useAdmin();
    return (
        <Fragment>
            <RAButton
                label="Delete"
                onClick={handleDelete}
                startIcon={<DeleteIcon />}
                disabled={isLoading}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this sub category?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={confirmDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

export const SubCategoryList: FC<ListProps> = (props) => {
    return (
        <List {...props}>
            <Datagrid>
                <ReferenceField source="parent_id" reference="categories" label="Category">
                    <TextField source="category_name" />
                </ReferenceField>
                <TextField source="sub_category_name" label="Sub Category" />
                <TextField source="sub_category_description" label="Description" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};