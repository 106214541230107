import React from 'react';
import { Create } from 'react-admin';
import { CustomerForm } from './CustomerForm';
import useAdmin from '../hooks/useAdmin';

export const CustomerCreate = (props: any) => {
    useAdmin();
    return (
        <Create title="Create Customer" {...props}>
            <CustomerForm isEdit={false} />
        </Create>
    );
};

export default CustomerCreate;
