import * as React from 'react';
import { Create, SimpleForm, TextInput, useNotify, useRedirect, Toolbar, SaveButton  } from 'react-admin';
import { Button } from '@mui/material';
import useAdmin from '../hooks/useAdmin';

const CustomToolbar = () => {
    const redirect = useRedirect();

    const handleBack = () => {
        redirect('/categories');
    };

    return (
        <Toolbar>
            <SaveButton />
            <Button color="primary" onClick={handleBack} style={{ marginLeft: '10px' }}>Back</Button>
        </Toolbar>
    );
};

export const ProductSegmentCreate = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.productsegments.notification.created', {
            type: 'info',
            messageArgs: { smart_count: 1, name: 'Segment' }
        });
        redirect('/productsegments');
    };
    useAdmin();
    return (
        <Create {...props} mutationOptions={{onSuccess: onSuccess}}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <TextInput source="product_segment_name" label="Name"/>
                <TextInput source="product_segment_description" label="Description"/>
            </SimpleForm>
        </Create>
    );
};