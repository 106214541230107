import React, { useEffect, useState } from 'react';
import { SimpleForm, TextInput, NumberInput, BooleanInput, PasswordInput, SelectInput, SelectArrayInput, required, useDataProvider, maxLength, email, regex } from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { ICity, IState, roleChoices } from '../types'; 
import { useParams } from 'react-router-dom';
import { UserRole } from '../types/types';

interface UserFormProps {
    isEdit: boolean;
}

export const UserForm: React.FC<UserFormProps> = ({ isEdit }) => {
    const [isLoading, setIsLoading] = useState(true); 

    const dataProvider = useDataProvider();
    const [cities, setCities] = useState<{ id: number; name: string }[]>([]);
    const [userCities, setUserCities] = useState<number[]>([]);
    const validatePassword = isEdit ? undefined : required();
    const validateGST = regex(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, 'Invalid GST number');
    const validateGSTYear = regex(/^[0-9]{4}$/, 'Invalid year format');
    const validatePin = regex(/^[0-9]{5}$/, 'Invalid PIN');
    const validatePhoneLandline = regex(/^[0-9-]+$/, 'Invalid landline number');
    const validatePhoneMobile = regex(/^[0-9]{10}$/, 'Invalid mobile number');
    const validateUsername = [required(), regex(/^[A-Za-z0-9]{1,10}$/, 'Invalid username (only alphanumeric, max 10 characters)'), maxLength(10)];
    const params = useParams();
    const userId = params.id;
    const [states, setStates] = useState<IState[]>([]); 
    const role_id = localStorage.getItem('user_role_id');
    const userRole = role_id ? parseInt(role_id) as UserRole : null; 

    useEffect(() => {
        setIsLoading(true);
        const fetchCities = async () => {
            try {
                const citiesResponse = await dataProvider.getList('statecities/withstates', {
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'city_name', order: 'ASC' },
                    filter: {}
                });
                setCities(citiesResponse.data.map((city: ICity) => ({ id: city.id, name: city.city_name })));
                if (isEdit && userId) {
                    const userCitiesResponse = await dataProvider.getList(`users/getusercities/${userId}`, {
                        pagination: { page: 1, perPage: 1000 },
                        sort: { field: 'city_name', order: 'ASC' },
                        filter: {}
                    });
                    setUserCities(userCitiesResponse.data.map((city: ICity) => city.id));
                }
                const statesResponse = await dataProvider.getList('states', {
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'state_name', order: 'ASC' },
                    filter: {}
                });
               setStates(statesResponse.data);

                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchCities();
    }, [userId, isEdit, dataProvider]);

    if (isLoading) {
        return <div>Loading...</div>;
    }
  
    return (
        <SimpleForm>
        <Grid container spacing={2}>
            {/* Business Info */}
            <Grid item xs={12}>
                <Typography variant="h6">Business Info</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput source="company_name" label="Company Name" fullWidth />
            </Grid>
            <Grid item xs={6} sm={3}>
                <TextInput source="gst_number" label="GST Number" fullWidth validate={validateGST} />
            </Grid>
            <Grid item xs={6} sm={3}>
                <NumberInput source="gst_registration_year" label="GST Registration Year" fullWidth  validate={validateGSTYear} />
            </Grid>

            {/* Personal Info */}
            <Grid item xs={6} sm={6}>
                <TextInput source="user_first_name" label="First Name" fullWidth validate={[required(), maxLength(30)]}/>
            </Grid>
            <Grid item xs={6} sm={6}>
                <TextInput source="user_last_name" label="Last Name" fullWidth validate={[maxLength(30)]}/>
            </Grid>
            <Grid item xs={12}>
                <TextInput source="email_address" label="Email Address" fullWidth validate={[email()]}/>
            </Grid>

            {/* Address */}
            <Grid item xs={12} sm={6}>
                <TextInput source="address_line1" label="Address Line 1" fullWidth validate={[maxLength(50)]}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput source="address_line2" label="Address Line 2" fullWidth validate={[maxLength(50)]}/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="address_city" label="City" fullWidth validate={[maxLength(30)]}/>
            </Grid>
            <Grid item xs={4}>
                <SelectInput source="address_state" label="State" choices={states} optionText="state_name" optionValue="id" fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="address_pin" label="PIN" fullWidth validate={validatePin}/>
            </Grid>

            {/* Contact */}
            <Grid item xs={6}>
                <TextInput source="phone_landline" label="Landline Phone" fullWidth validate={validatePhoneLandline}/>
            </Grid>
            <Grid item xs={6}>
                <TextInput source="phone_mobile" label="Mobile Phone" fullWidth validate={validatePhoneMobile}/>
            </Grid>

            {/* Delivery Info */}
            <Grid item xs={12}>
                <Typography variant="h6">Delivery Info</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextInput source="delivery_contact_person" label="Delivery Contact Person" fullWidth validate={[maxLength(50)]}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput source="delivery_address_line1" label="Delivery Address Line 1" fullWidth validate={[maxLength(50)]}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextInput source="delivery_address_line2" label="Delivery Address Line 2" fullWidth validate={[maxLength(50)]}/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="delivery_address_city" label="Delivery City" fullWidth validate={[maxLength(30)]}/>
            </Grid>
            <Grid item xs={4}>
                <SelectInput source="delivery_address_state" label="Delivery State" choices={states} optionText="state_name" optionValue="id" fullWidth/>
            </Grid>
            <Grid item xs={4}>
                <TextInput source="delivery_address_pin" label="Delivery PIN" fullWidth validate={validatePin}/>
            </Grid>

            {/* Additional Info */}
            <Grid item xs={12}>
                <TextInput source="payment_terms" label="Payment Terms" fullWidth validate={[maxLength(50)]}/>
            </Grid>

            {/* Profile Info */}
            <Grid item xs={12}>
                <Typography variant="h6">Profile Info</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextInput source="user_name" label="User Name" fullWidth validate={validateUsername}/>

            </Grid>
            <Grid item xs={12}>
                <PasswordInput source="user_password" label="Password" fullWidth validate={validatePassword}/>
            </Grid>
            {userRole === UserRole.Admin && (     
                <>       
                <Grid item xs={12}>
                    <SelectInput 
                        source="role_id" 
                        label="Role" 
                        choices={roleChoices} 
                        optionText="name" 
                        optionValue="id" 
                        validate={required()}
                        fullWidth 
                    />
                </Grid>

                <Grid item xs={12}>
                    <SelectArrayInput
                        label="Warehouses"
                        source="cities"
                        choices={cities}
                        defaultValue={userCities}
                        optionText="name"
                        optionValue="id"
                        translateChoice={false}
                        fullWidth
                        validate={[required(), maxLength(30)]}
                    />
                </Grid>
                </>
            )}
        </Grid>
    </SimpleForm>

    );
};
