// /Users/gaurav/Dropbox/Projects/CredCoApp/Admin/CredCo/client/src/stateCities/StateCityEdit.tsx
import * as React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, BooleanInput, ReferenceInput, SelectInput, required, maxLength, minValue, Toolbar, SaveButton, useNotify, useRedirect } from 'react-admin';
import { Button } from '@mui/material';
import useAdmin from '../hooks/useAdmin';
const CustomToolbar = () => {
    const redirect = useRedirect();

    const handleBack = () => {
        redirect('/statecities');
    };

    return (
        <Toolbar>
            <SaveButton />
            <Button color="primary" onClick={handleBack} style={{ marginLeft: '10px' }}>Back</Button>
        </Toolbar>
    );
};

export const StateCityEdit = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.statecities.notification.updated', {
            type: 'info',
            undoable: true,
            messageArgs: { smart_count: 1, name: 'City' }
        });
        redirect('/statecities');
    };
    useAdmin();
    return (
        <Edit {...props} mutationOptions={{onSuccess: onSuccess}}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <ReferenceInput source="state_id" reference="states/getAllStates" alwaysOn>
                    <SelectInput optionText="state_name" label="State" validate={[required()]}/>
                </ReferenceInput>
                <TextInput source="city_name" label="City Name" validate={[required(), maxLength(500)]}/>
                <BooleanInput source="active" label="Active"/>
                <BooleanInput source="app_active" label="Active on App"/>

            </SimpleForm>
        </Edit>
    );
};

export default StateCityEdit;
