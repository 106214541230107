import React, { useEffect, useState } from 'react';
import { SimpleForm, TextInput, NumberInput, BooleanInput, SelectInput, required, useDataProvider, maxLength, email, regex } from 'react-admin';
import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ICity, IState, roleChoices } from '../types'; 

interface CustomerFormProps {
    isEdit: boolean;
}

export const CustomerForm: React.FC<CustomerFormProps> = ({ isEdit }) => {
    const [isLoading, setIsLoading] = useState(true); 
    const dataProvider = useDataProvider();
    const [states, setStates] = useState<IState[]>([]); 
    const [cities, setCities] = useState<{ id: number; name: string }[]>([]);

    const validateEmail = [email()];
    const validateGST = regex(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/, 'Invalid GST number');
    const validatePhone = [required(),regex(/^[0-9]{10}$/, 'Invalid phone number')];
    const validatePin = regex(/^[0-9]{6}$/, 'Invalid PIN');
    const validateCustomerUsername = [required(), maxLength(50)];
    const validateName = [maxLength(100)];
    const validateCompanyName = maxLength(200);

    const params = useParams();
    const customerId = params.id;

    useEffect(() => {
        setIsLoading(true);
        const fetchCities = async () => {
            try {
                const citiesResponse = await dataProvider.getList('statecities/withstates', {
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'city_name', order: 'ASC' },
                    filter: {}
                });
                setCities(citiesResponse.data.map((city: ICity) => ({ id: city.id, name: city.city_name })));
                const statesResponse = await dataProvider.getList('states', {
                    pagination: { page: 1, perPage: 100 },
                    sort: { field: 'state_name', order: 'ASC' },
                    filter: {}
                });
               setStates(statesResponse.data);

                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchCities();
    }, [customerId, isEdit, dataProvider]);
    
    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <SimpleForm>
            <Grid container spacing={2}>
                {/* Business Info */}
                <Grid item xs={12}>
                    <Typography variant="h6">Business Info</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput source="company_name" label="Company Name" fullWidth validate={validateCompanyName} />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TextInput source="gst_number" label="GST Number" fullWidth validate={validateGST} />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <NumberInput source="gst_registration_year" label="GST Registration Year" fullWidth />
                </Grid>

                {/* Personal Info */}
                <Grid item xs={6} sm={6}>
                    <TextInput source="customer_first_name" label="First Name" fullWidth validate={validateName}/>
                </Grid>
                <Grid item xs={6} sm={6}>
                    <TextInput source="customer_last_name" label="Last Name" fullWidth validate={validateName}/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="email_address" label="Email Address" fullWidth validate={validateEmail}/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="customer_username" label="Username" fullWidth validate={validateCustomerUsername}/>
                </Grid>
                {/* Address */}
                <Grid item xs={12} sm={6}>
                    <TextInput source="address_line1" label="Address Line 1" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput source="address_line2" label="Address Line 2" fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="address_city" label="City" fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <SelectInput source="address_state" label="State" choices={states} optionText="name" optionValue="id" fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="address_pin" label="PIN" fullWidth validate={validatePin}/>
                </Grid>

                {/* Contact */}
                <Grid item xs={6}>
                    <TextInput source="phone_landline" label="Landline Phone" fullWidth />
                </Grid>
                <Grid item xs={6}>
                    <TextInput source="phone_mobile" label="Mobile Phone" fullWidth validate={validatePhone}/>
                </Grid>

                {/* Delivery Info */}
                <Grid item xs={12}>
                    <Typography variant="h6">Delivery Info</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextInput source="delivery_contact_person" label="Delivery Contact Person" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput source="delivery_address_line1" label="Delivery Address Line 1" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput source="delivery_address_line2" label="Delivery Address Line 2" fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="delivery_address_city" label="Delivery City" fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <SelectInput source="delivery_address_state" label="Delivery State" choices={states} optionText="name" optionValue="id" fullWidth/>
                </Grid>
                <Grid item xs={4}>
                    <TextInput source="delivery_address_pin" label="Delivery PIN" fullWidth validate={validatePin}/>
                </Grid>

                {/* Additional Info */}
                <Grid item xs={12}>
                    <TextInput source="payment_terms" label="Payment Terms" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <BooleanInput source="has_newsletter" label="Subscribe to Newsletter" fullWidth />
                </Grid>

            </Grid>
        </SimpleForm>
    );
};

export default CustomerForm;
