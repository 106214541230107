import * as React from 'react';
import { Edit, SimpleForm, TextInput, DateInput, required, maxLength, minLength, Toolbar, SaveButton, useNotify, useRedirect } from 'react-admin';
import { Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useAdmin from '../hooks/useAdmin';

const CustomToolbar = () => {
    const redirect = useRedirect();

    const handleBack = () => {
        redirect('/offers');
    };

    return (
        <Toolbar>
            <SaveButton />
            <Button color="primary" onClick={handleBack} style={{ marginLeft: '10px' }}>Back</Button>
        </Toolbar>
    );
};

export const OfferEdit = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.offers.notification.updated', {
            type: 'info',
            undoable: true,
            messageArgs: { smart_count: 1, name: 'Offer' }
        });
        redirect('/offers');
    };
    useAdmin();
    return (
        <Edit {...props} mutationOptions={{onSuccess: onSuccess}}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <TextInput source="post" label="Offer Details" validate={[required(), maxLength(2000)]}/>
                <DateInput source="expirydate" label="Expiry Date" validate={required()}/>
            </SimpleForm>
        </Edit>
    );
};

export default OfferEdit;
