import * as React from 'react';
import { List, Datagrid, TextField, EditButton, DeleteButton, TextInput } from 'react-admin';
import useAdmin from '../hooks/useAdmin';

const UserFilter = [
    <TextInput label="User" source="user_search" resettable alwaysOn/>,
];


export const UserList = (props: any) => {
    useAdmin();
    return (
        <List filters={UserFilter} {...props}>
            <Datagrid>
                <TextField source="company_name" label="Company Name" />
                <TextField source="user_name" label="User Name" />
                <TextField source="user_first_name" label="First Name" />
                <TextField source="user_last_name" label="Last Name" />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
};

export default UserList;
