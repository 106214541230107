// /Users/gaurav/Dropbox/Projects/CredCoApp/Admin/CredCo/client/src/states/index.ts
import StateIcon from '@mui/icons-material/Flag';

import { StateList } from './StateList';
import { StateEdit } from './StateEdit';
import { StateCreate } from './StateCreate';

export default {
    list: StateList,
    edit: StateEdit,
    create: StateCreate,
    icon: StateIcon,
};
