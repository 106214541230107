import React from 'react';
import { Edit } from 'react-admin';
import { CustomerForm } from './CustomerForm';
import useAdmin from '../hooks/useAdmin';

export const CustomerEdit = (props: any) => {
    useAdmin();
    return (
        <Edit title="Edit Customer" {...props}>
            <CustomerForm isEdit={true} />
        </Edit>
    );
};

export default CustomerEdit;
