import React, { FC, useState, useEffect, Fragment } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    useNotify,
    useDelete,
    useDataProvider,
    FormDataConsumer,
    ReferenceInput,
    SelectInput,
    TextInput,
    Button as RAButton,
    useRecordContext,
    FunctionField
} from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { ISubCategory, IProduct } from '../types/types';
import useAdmin from '../hooks/useAdmin';

const DeleteWithConfirmButton: FC = () => {
    const record = useRecordContext<IProduct>();
    const [deleteOne, { isLoading }] = useDelete();
    const [open, setOpen] = useState(false);
    const notify = useNotify();

    const handleDelete = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const confirmDelete = async () => {
        await deleteOne('products', { id: record.id });
        notify('resources.products.notification.deleted', {
            type: 'info',
            messageArgs: { smart_count: 1, name: 'Product' }
        });
        setOpen(false);
    };

    return (
        <Fragment>
            <RAButton
                label="Delete"
                onClick={handleDelete}
                startIcon={<DeleteIcon />}
                disabled={isLoading}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this product?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={confirmDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

const SubCategorySelectInput = ({ categoryId, subCategoryId }: { categoryId: number, subCategoryId: number }) => {
    const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (categoryId) {
            dataProvider.getList('subcategories/bycategory', {
                filter: { category_id: categoryId },
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'sub_category_name', order: 'ASC' }
            }).then(({ data }) => setSubCategories(data));
        } else {
            setSubCategories([]);
        }
    }, [categoryId, dataProvider]);

    return (
        <SelectInput
            source="sub_category_id"
            label="Sub Category"
            choices={subCategories}
            optionText="sub_category_name"
            optionValue="id"
            defaultValue={subCategoryId}
        />
    );
};

const ProductFilter = [
    <ReferenceInput source="category_id" reference="categories" label="Category" alwaysOn>
        <SelectInput optionText="category_name"/>
    </ReferenceInput>,
    <FormDataConsumer alwaysOn>
    {({ formData, ...rest }) => ( 
        formData.category_id ? 
            <SubCategorySelectInput categoryId={formData.category_id} subCategoryId={formData.sub_category_id} {...rest} /> 
            : null
    )}
    </FormDataConsumer>,
    <TextInput label="Search" source="product_search" resettable alwaysOn/>,
    <TextInput label="Description" source="product_description" resettable/>,
    <TextInput label="Grade" source="product_grade" resettable/>,
    <TextInput label="MFI" source="product_mfi" resettable/>,

];

export const ProductList: FC<any> = (props) => {
    const navigate = useNavigate();
    const userId = localStorage.getItem('user_id');

    const PriceLinkField = ({ record }: { record: IProduct }) => (
        <a href="#" onClick={(e) => {
            e.preventDefault();
            navigate(`/producthistory?filter=${encodeURIComponent(JSON.stringify({ id: record.id }))}`);
        }}>
            Price
        </a>
    );

    const ShippingLinkField = ({ record }: { record: IProduct }) => (
        <a href="#" onClick={(e) => {
            e.preventDefault();
            navigate(`/productshipping?filter=${encodeURIComponent(JSON.stringify({ id: record.id, user_id: userId }))}`);
        }}>
            View
        </a>
    );
    useAdmin();
    return (
        <List filters={ProductFilter} {...props}>
            <Datagrid>
                <TextField source="category_name" label="Category" />
                <TextField source="sub_category_name" label="Sub Category" />
                <TextField source="product_name" label="Description" />
                <TextField source="product_grade" label="Grade" />
                <TextField source="product_mfi" label="MFI" />
                <TextField source="current_price" label="Current Price" />
                <FunctionField label="Price" render={(record: IProduct) => <PriceLinkField record={record} />} />
                <FunctionField label="Shipping" render={(record: IProduct) => <ShippingLinkField record={record} />} />
                <TextField source="product_commission" label="Comm" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};
