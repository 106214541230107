// /Users/gaurav/Dropbox/Projects/CredCoApp/Admin/CredCo/client/src/cityShipping/index.ts
import ShippingIcon from '@mui/icons-material/LocalShipping';

import { CityShippingList } from './CityShippingList';
import { CityShippingEdit } from './CityShippingEdit';
import { CityShippingCreate } from './CityShippingCreate';

export default {
    list: CityShippingList,
    edit: CityShippingEdit,
    create: CityShippingCreate,
    icon: ShippingIcon,
};
