// /Users/gaurav/Dropbox/Projects/CredCoApp/Admin/CredCo/client/src/states/StateEdit.tsx
import * as React from 'react';
import { Edit, SimpleForm, TextInput, required, maxLength, BooleanInput, Toolbar, SaveButton, useNotify, useRedirect } from 'react-admin';
import { Button } from '@mui/material';
import useAdmin from '../hooks/useAdmin';
const CustomToolbar = () => {
    const redirect = useRedirect();

    const handleBack = () => {
        redirect('/states');
    };

    return (
        <Toolbar>
            <SaveButton />
            <Button color="primary" onClick={handleBack} style={{ marginLeft: '10px' }}>Back</Button>
        </Toolbar>
    );
};

export const StateEdit = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.states.notification.updated', {
            type: 'info',
            undoable: true,
            messageArgs: { smart_count: 1, name: 'State' }
        });
        redirect('/states');
    };
    useAdmin();
    return (
        <Edit {...props} mutationOptions={{onSuccess: onSuccess}}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <TextInput source="state_name" label="State Name" validate={[required(), maxLength(300)]}/>
                <TextInput source="state_code" label="State Code" validate={[required(), maxLength(2)]}/>
                <TextInput source="zone" label="Zone" validate={[required(), maxLength(1)]}/>
                <BooleanInput source="active" label="Active"/>
            </SimpleForm>
        </Edit>
    );
};

export default StateEdit;
