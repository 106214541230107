// /Users/gaurav/Dropbox/Projects/CredCoApp/Admin/CredCo/client/src/cityShipping/CityShippingCreate.tsx
import * as React from 'react';
import { Create, SimpleForm, ReferenceInput, SelectInput, NumberInput, required, minValue, Toolbar, SaveButton, useNotify, useRedirect } from 'react-admin';
import { Button } from '@mui/material';
import useAdmin from '../hooks/useAdmin';


const CustomToolbar = () => {
    const redirect = useRedirect();

    const handleBack = () => {
        redirect('/cityshipping');
    };

    return (
        <Toolbar>
            <SaveButton />
            <Button color="primary" onClick={handleBack} style={{ marginLeft: '10px' }}>Back</Button>
        </Toolbar>
    );
};


export const CityShippingCreate = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('resources.cityshipping.notification.created', {
            type: 'info',
            messageArgs: { smart_count: 1, name: 'City Shipping' }
        });
        redirect('/cityshipping');
    };

    const onFailure = (error: any) => {
        console.log('Error object:', error);
    
        // Extracting the error code from the server response
        let errorCode;
        if (error && error.body && error.body.code) {
            errorCode = error.body.code;
        } else if (error && error.message && error.message.code) {
            errorCode = error.message.code;
        } else if (error && error.code) {
            errorCode = error.code;
        }
    
        // Handling the unique constraint violation error
        if (errorCode === '23505') {
            notify(`Error: A city shipping entry with the same 'from' and 'to' cities already exists.`, { type: 'warning' });
        } else {
            // Fallback error message for other types of errors
            const errorMessage = error.message || 'An error occurred';
            notify(`Error: ${errorMessage}`, { type: 'warning' });
        }
    };
    useAdmin();
    return (
        <Create {...props} mutationOptions={{onSuccess: onSuccess}}>
            <SimpleForm toolbar={<CustomToolbar />}>
            <ReferenceInput source="city_from" reference="statecities/withstates" alwaysOn>
                <SelectInput optionText="city_name" label="City From" validate={[required()]}/>
            </ReferenceInput>
            <ReferenceInput source="city_to" reference="statecities/withstates" alwaysOn>
                <SelectInput optionText="city_name" label="City To" validate={[required()]}/>
            </ReferenceInput>
            <NumberInput source="shipping_charge" label="Shipping Charge" validate={[required(), minValue(0)]}/>
            </SimpleForm>
        </Create>
    );
};


//<ReferenceInput source="city_from" reference="state_cities" label="City From" validate={required()}>
//<SelectInput optionText="id" />
//</ReferenceInput>
//<ReferenceInput source="city_to" reference="state_cities" label="City To" validate={required()}>
//<SelectInput optionText="id" />
//</ReferenceInput>

export default CityShippingCreate;
