// useSellerName.js
import { useDataProvider } from 'react-admin';
import { useState, useEffect } from 'react';

const useSellerName = (sellerId) => {
    const [sellerName, setSellerName] = useState('');
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (!sellerId) {
            setSellerName('');
            return;
        }

        const fetchSellerName = async () => {
            try {
                const response = await dataProvider.getList('sellers/byid', {
                    filter: { id: sellerId },
                    pagination: { page: 1, perPage: 1 },
                    sort: { field: 'id', order: 'ASC' }
                });
                if (response && response.data && response.data.length > 0) {
                    setSellerName(response.data[0].company_name); // Assuming 'company_name' is the field
                } else {
                    setSellerName('');
                }
            } catch (error) {
                console.error('Error fetching seller name:', error);
                setSellerName('');
            }
        };

        fetchSellerName();
    }, [sellerId, dataProvider]);

    return sellerName;
};
export default useSellerName;
