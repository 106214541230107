// client/src/orders/OrderList.tsx
import React, { Fragment, useCallback,SyntheticEvent,  } from 'react';
import { 
    List, Datagrid, TextField, EditButton, NumberField, 
    useListContext, TopToolbar, FilterButton, ExportButton, useRecordContext, FunctionField
} from 'react-admin';
import { Tabs, Tab, useMediaQuery, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useAdmin from '../hooks/useAdmin';
import { useNavigate } from 'react-router-dom';
import { IOrder } from '../types/types';


interface ProductPricingFieldProps {
    record: IOrder;
}

const tabs = [
    { id: '1', name: 'Pending' },
    { id: '2', name: 'In-Transit' },
    { id: '3', name: 'Completed' },
];


const TabbedDatagrid = () => {
    const listContext = useListContext();
    const { filterValues, setFilters, displayedFilters } = useListContext();
    const theme : Theme = useTheme();
    const isXSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const navigate = useNavigate();

    const handleChange = useCallback(
        (_event: SyntheticEvent, value: string) => {
            setFilters({ ...filterValues, status: value }, displayedFilters);
        },
        [filterValues, setFilters, displayedFilters]
    );
    const OrderHistoryField = ({ record }: ProductPricingFieldProps) => (
        <a href="#" onClick={(e) => {
            e.preventDefault();
            navigate(`/orderhistory?filter=${encodeURIComponent(JSON.stringify({ order_id: record.id }))}`);
        }}>
            {record.history_count}
        </a>
    );
    useAdmin();
    return (
        <Fragment>
            <Tabs value={filterValues.status} onChange={handleChange} centered>
                {tabs.map(choice => (
                    <Tab key={choice.id} label={choice.name} value={choice.id} />
                ))}
            </Tabs>
            <Datagrid>  
                <NumberField source="id" label="ID" />
                <TextField source="order_date" label="Order Date" />
                <TextField source="customer_contact" label="Customer" />
                <TextField source="product_name" label="Product Name" />
                <TextField source="product_grade" label="Product Grade" />
                <NumberField source="order_quantity" label="Qty" />
                <NumberField source="order_price" label="Price" />
                <NumberField source="order_total" label="Total" />
                <FunctionField label="History" render={(record: IOrder) => <OrderHistoryField record={record} />} sortable source="company_name" />
                <EditButton />
            </Datagrid>
        </Fragment>
    );
};

export const OrderList = (props:any) => (
    <List
        {...props}
        
        filterDefaultValues={{ status: '1' }} // Default to 'Pending'
    >
        <TabbedDatagrid />
    </List>
);

export default OrderList;
