import * as React from 'react';
import CustomerIcon from '@mui/icons-material/PersonAdd';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

interface Props {
    value?: number;
}

const PendingOrders = (props: Props) => {
    const { value } = props;
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/customers"
            icon={CustomerIcon}
            title={'New Customers'}
            subtitle={value}
        />
    );
};

export default PendingOrders;