import React, { FC, useState, Fragment } from 'react';
import { List, Datagrid, TextField, BooleanField, EditButton, useNotify, useDelete, ListProps, Button as RAButton, useRecordContext, FunctionField, BooleanInput } from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { IState } from '../types/types'; // Adjust the import path as necessary
import useAdmin from '../hooks/useAdmin';

const StatesFilter = [
    <BooleanInput label="Active" source="active" alwaysOn />,
];

const DeleteWithConfirmButton: FC = () => {
    const record = useRecordContext<IState>();
    const [deleteOne, { isLoading }] = useDelete();
    const [open, setOpen] = useState(false);
    const notify = useNotify();

    const handleDelete = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const confirmDelete = async () => {
        await deleteOne('states', { id: record.id });
        notify('resources.states.notification.deleted', {
            type: 'info',
            messageArgs: { smart_count: 1, name: 'State' }
        });
        setOpen(false);
    };
    useAdmin();
    return (
        <Fragment>
            <RAButton
                label="Delete"
                onClick={handleDelete}
                startIcon={<DeleteIcon />}
                disabled={isLoading}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this state?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={confirmDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};

const StateNameField = ({ record }: { record: IState }) => {
    const navigate = useNavigate();

    return (
        <a href="#" onClick={(e) => {
            e.preventDefault();
            navigate(`/stateCities?filter=${encodeURIComponent(JSON.stringify({ state_id: record.id }))}`);
        }}>
            {record.state_name}
        </a>
    );
};

export const StateList: FC<ListProps> = (props) => {
    return (
        <List filters={StatesFilter} {...props}>
            <Datagrid>
                <FunctionField label="State Name" render={(record: IState) => <StateNameField record={record} />} sortable source="state_name" />
                <TextField source="state_code" />
                <TextField source="zone" />
                <BooleanField source="active" />
                <EditButton />
                <DeleteWithConfirmButton />
            </Datagrid>
        </List>
    );
};
