import React from 'react';
import { Create } from 'react-admin';
import { UserForm } from './UserForm';
import useAdmin from '../hooks/useAdmin';

export const UserCreate = (props: any) => {
    useAdmin();
    return (
        <Create title="Create User" {...props}>
            <UserForm isEdit={false} />
        </Create>
    );
};

export default UserCreate;