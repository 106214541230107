import React, { FC, useState, useEffect, Fragment } from 'react';
import {
    List,
    Datagrid,
    TextField,
    EditButton,
    useNotify,
    useDelete,
    ListProps,
    Button as RAButton,
    useRecordContext,
    ReferenceInput,
    SelectInput,
    TextInput,
    BooleanInput,
    Title,
    FormDataConsumer,
    useUpdate,
    useRefresh,
    useDataProvider
} from 'react-admin';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IProductPricing, ISubCategory, IUserCities } from '../types/types';
import EdiText from 'react-editext';
import { useNavigate } from 'react-router-dom';
import './customStyles.css';
import useSellerName from '../custom/useSellerName';
import useCheckQueryParam from '../hooks/useCheckQueryParam';

let seller_id = localStorage.getItem('user_id');
let user_role = localStorage.getItem('user_role');
let selectedCityGlobal = '';

const handleCityChange = (cityId: string) => {
    selectedCityGlobal = cityId;
};

interface Seller {
    seller_id: number;
    company_name: string;
    // Add any other properties that sellers have
}

const CustomField = () => {
    const record = useRecordContext<IProductPricing>();
    const category = record.sub_category_name ? `${record.category_name} - ${record.sub_category_name}` : record.category_name;
    const mfiText = record.product_mfi ? `, ${record.product_mfi} MFI` : '';

    return (
        <div style={{ lineHeight: '0.1' }}>
            <p>{category}</p>
            <h3>{record.product_name}</h3>
            <p>{`${record.product_grade}${mfiText}`}</p>
        </div>
    );
};

interface EditableTextFieldProps {
    source: keyof IProductPricing;
}

const extractSellerIdFromURL = () => {
    const queryString = window.location.hash.split('?')[1] || '';
    const currentParams = new URLSearchParams(queryString);
    const currentFilterString = currentParams.get('filter');

    if (currentFilterString) {
        const currentFilters = JSON.parse(decodeURIComponent(currentFilterString));
        return currentFilters.seller_id ? currentFilters.seller_id.toString() : '';
    }

    return '';
};
const ediTextStyle = {
    width: '150px', 
};


const EditableTextField = ({ source }: EditableTextFieldProps) => {
    const record = useRecordContext<IProductPricing>();
    const [update] = useUpdate();
    const [value, setValue] = useState(record[source]?.toString() || '');
    const refresh = useRefresh();

    // Validation function for EdiText
    const validateInput = (val: string) => {
        const trimmedValue = val.trim();
        const numericValue = parseFloat(trimmedValue);
        return trimmedValue !== '' && !isNaN(numericValue) && numericValue > 0;
    };

    const onSave = async (newValue: string) => {
        const numericValue = parseFloat(newValue);
        setValue(newValue);
        const updateParams = {
            id: record.product_id,
            data: { [source]: numericValue, seller_id: extractSellerIdFromURL(), city_id: selectedCityGlobal },
            previousData: record 
        };

        try {
            await update('productpricing', updateParams); 
            setTimeout(refresh, 100); 
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <EdiText
            type="text"
            value={value}
            submitOnEnter
            cancelOnEscape            
            onSave={onSave}
            validation={validateInput}
            validationMessage="Please enter a valid numeric value greater than 0."
            viewContainerClassName="editTextCustomWidth" 
            editContainerClassName="editTextCustomWidth"
        />
    );
};

const SubCategorySelectInput = ({ categoryId, subCategoryId }: { categoryId: number, subCategoryId: number }) => {
    const [subCategories, setSubCategories] = useState<ISubCategory[]>([]);
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (categoryId) {
            dataProvider.getList('subcategories/bycategory', {
                filter: { category_id: categoryId },
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'sub_category_name', order: 'ASC' }
            }).then(({ data }) => setSubCategories(data));
        } else {
            setSubCategories([]);
        }
    }, [categoryId, dataProvider]);

    return (
        <SelectInput
            source="sub_category_id"
            label="Sub Category"
            choices={subCategories}
            optionText="sub_category_name"
            optionValue="id"
            defaultValue={subCategoryId}
        />
    );
};

const UserCitiesSelectInput = ({ onCityChange }: { onCityChange: (cityId: string) => void }) => {
    const [userCities, setUserCities] = useState<IUserCities[]>([]);
    const [selectedCity, setSelectedCity] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const dataProvider = useDataProvider();

    useEffect(() => {
        dataProvider.getList('users/usercities', {
            filter: { user_id: extractSellerIdFromURL() },
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'city_name', order: 'ASC' }
        }).then(({ data }) => {
            setUserCities(data);
            if (data.length > 0) {
                const initialCityId = data[0].id.toString();
                if (!selectedCity) {
                    setSelectedCity(initialCityId);
                    onCityChange(initialCityId); // Initialize with the first city if no city is selected
                }
            }
            setIsLoading(false);
        });
    }, [dataProvider, onCityChange, selectedCity]);

    return (
        <SelectInput
            key={extractSellerIdFromURL()}
            source="city_id"
            label="Warehouse"
            choices={userCities}
            optionText="city_name"
            optionValue="id"
            disabled={isLoading || userCities.length === 0}
            value={selectedCity || (userCities.length > 0 ? userCities[0].id.toString() : '')}
            onChange={(e) => {
                const newCityId = e.target.value;
                console.log("New selectedCity:", newCityId);
                setSelectedCity(newCityId);
                onCityChange(newCityId); // Update the city based on selection
            }}
        />
    );
};


const ProductPricingFilter = [
    <FormDataConsumer alwaysOn>
    {({ formData, ...rest }) => (
        <UserCitiesSelectInput  onCityChange={handleCityChange} {...rest} /> 
    )}
    </FormDataConsumer>,

    <ReferenceInput source="category_id" reference="categories" alwaysOn>
        <SelectInput optionText="category_name" label="Category"/>
    </ReferenceInput>,
    <FormDataConsumer alwaysOn>
    {({ formData, ...rest }) => (
        formData.category_id ? 
            <SubCategorySelectInput categoryId={formData.category_id} subCategoryId={formData.sub_category_id} {...rest} /> 
            : null
    )}
    </FormDataConsumer>,
    <TextInput label="Search" source="product_search" resettable alwaysOn/>,
    <TextInput label="Description" source="product_description" resettable />,
    <TextInput label="Grade" source="product_grade" resettable />,
    <TextInput label="MFI" source="product_mfi" resettable />,
    <BooleanInput label="My Listings" source="active" alwaysOn />,
];

const DeleteWithConfirmButton: FC = () => {
    const record = useRecordContext<IProductPricing>();
    const [deleteOne, { isLoading }] = useDelete();
    const [open, setOpen] = useState(false);
    const notify = useNotify();

    const handleDelete = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const confirmDelete = async () => {
        await deleteOne('productpricing', { id: record.id });
        notify('resources.productpricing.notification.deleted', {
            type: 'info',
            messageArgs: { smart_count: 1, name: 'Product Pricing' }
        });
        setOpen(false);
    };

    return (
        <Fragment>
            <RAButton
                label="Delete"
                onClick={handleDelete}
                startIcon={<DeleteIcon />}
                disabled={isLoading}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this product pricing record?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={confirmDelete} color="error">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
};


export const ProductPricingList: FC<ListProps> = (props) => {
    const sellerId = extractSellerIdFromURL();
    const sellerName = useSellerName(sellerId);
    const notify = useNotify();
    useCheckQueryParam(sellerId); 
    return (
        <div>
            <Title title={sellerName ? `${sellerName} | ` : 'Loading Title...'} />
            <List {...props} 
                  filters={ProductPricingFilter} 
                  sort={{ field: 'product_name', order: 'ASC' }} // Replace 'desiredFieldName' with your default sort field
             >
                <Datagrid bulkActionButtons={false}>
                    {/* Other fields as needed */}
                    <TextField source="category_name" label="Category" />
                    <TextField source="sub_category_name" label="Sub Category" />
                    <TextField source="product_name" label="Description" />
                    <TextField source="product_grade" label="Grade" />
                    <TextField source="product_mfi" label="MFI" />
                    <EditableTextField source="price" />
                    <TextField source="product_status" label="Status" />
                    <DeleteWithConfirmButton />
                </Datagrid>
            </List>
        </div>
    );
};

export default ProductPricingList;
