import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            month_history: '30 Day Revenue History',
            new_orders: 'New Orders',
            pending_reviews: 'Pending Reviews',
            all_reviews: 'See all reviews',
            new_customers: 'New Customers',
            all_customers: 'See all customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to the react-admin e-commerce demo',
                subtitle:
                    "This is the admin of an imaginary poster shop. Feel free to explore and modify the data - it's local to your computer, and will reset each time you reload.",
                ra_button: 'react-admin site',
                demo_button: 'Source for this demo',
            },
        },
        menu: {
            sales: 'Sales',
            customers: 'Customers',
            products: 'Products',
            users: 'Users',
            shipping: 'Shipping',
            orders: 'Orders',
            profile: 'Profile',
        },
    },
    resources: {
        customers: {
            name: 'Customer |||| Customers',
            page: {
                delete: 'Delete Customer',
            },
            errors: {
                password_mismatch:
                    'The password confirmation is not the same as the password.',
            },
        },
        products: {
            name: 'Product |||| Products',
            notification: { 
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },            
       },
       cityshipping: {
            name: 'Shipping |||| Shipping',
            notification: { 
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },            
        },       
       orders: {
        name: 'Order |||| Orders',
        notification: {
            updated: 'The %{name} was updated successfully',
            created: 'The %{name} was created successfully',
            deleted: 'The %{name} was deleted successfully',
            },            
        },
        productpricing: {
            name: 'Price |||| Pricing',
            notification: {
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },            
       },
       sellerfilter: {
            name: 'Pricing |||| Pricing',
            notification: {
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },            
        },
        orderhistory: {
            name: 'Comment |||| Comment',
            notification: {
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },            
        },
        categories: {
            name: 'Category |||| Categories',
            fields: {
                products: 'Products',
            },
            notification: {
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },
        },
        subcategories: {
            name: 'Subcategory |||| SubCategories',
            fields: {
                products: 'Products',
            },
            notification: {
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },
        },
        productsegments: {
            name: 'Segments |||| Segments',
            fields: {
                products: 'Segments',
            },
            notification: {
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },            
        },
        segments: {
            name: 'Segments |||| Segments',
            fields: {
                products: 'Segments',
            },
            notification: {
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },            
        },
        customersegments: {
            name: 'Segments |||| Segments',
            fields: {
                products: 'Products',
            },
        },
        sellers: {
            name: 'Sellers |||| Sellers',
            fields: {
                products: 'Products',
            },
        },       
        users: {
            name: 'User |||| Users',
            fields: {
                products: 'Products',
            },
            notification: {
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },            
        }, 
        profile: {
            name: 'Profile |||| Profile',
            fields: {
                products: 'Products',
            },
            notification: {
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },            
        }, 

        states: {
            name: 'Location |||| Locations',
            fields: {
                products: 'States',
            },
            notification: {
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },            
        },       
        statecities: {
            name: 'City |||| Cities',
            fields: {
                products: 'Cities',
            },
            notification: {
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },            
        },      
        unregistered: {
            name: 'Unregistered |||| Unregistered',
            fields: {
                products: 'Cities',
            },
            notification: {
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },            
        },      
        
        offers: {
            name: 'Offers |||| Offers',
            fields: {
                offers: 'Offers',
            },
            notification: {
                updated: 'The %{name} was updated successfully',
                created: 'The %{name} was created successfully',
                deleted: 'The %{name} was deleted successfully',
            },
        },

  
    },

};

export default customEnglishMessages;
