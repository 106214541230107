import React from 'react';
import { Edit, SimpleForm, TextInput, DateInput, NumberInput, ReferenceInput, SelectInput, required, useNotify, useRedirect } from 'react-admin';

export const OrderEdit = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('Order updated successfully', { type: 'info' });
        redirect('/orders');
    };

    return (
        <Edit {...props}>
            <SimpleForm>
                <NumberInput source="id" disabled label="Order Id" />
                <NumberInput source="order_total" label="Order Total" disabled />
                <ReferenceInput source="order_status_id" reference="orders/getStatus" alwaysOn>
                    <SelectInput optionText="order_status" label="Order Status" validate={[required()]} />
                </ReferenceInput>
                <TextInput source="order_tracking_url" />
            </SimpleForm>
        </Edit>
    );
};