import * as React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, DateTimeInput, Toolbar, SaveButton, useNotify, useRedirect } from 'react-admin';
import { Button } from '@mui/material';

const CustomToolbar = () => {
    const redirect = useRedirect();

    const handleBack = () => {
        redirect('/productpricing');
    };

    return (
        <Toolbar>
            <SaveButton />
            <Button color="primary" onClick={handleBack} style={{ marginLeft: '10px' }}>Back</Button>
        </Toolbar>
    );
};

export const ProductPricingEdit = (props: any) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = () => {
        notify('Product pricing updated successfully', { type: 'info' });
        redirect('/productpricing');
    };

    return (
        <Edit {...props} mutationOptions={{ onSuccess }}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <NumberInput source="product_id" label="Product ID" />
                <NumberInput source="user_id" label="User ID" />
                <DateTimeInput source="price_date" label="Price Date" />
                <NumberInput source="price" label="Price" />
            </SimpleForm>
        </Edit>
    );
};

export default ProductPricingEdit;
