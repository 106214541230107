import React, { useEffect, useState } from 'react';
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@mui/material';
import { Segment } from '../types/types';
import { useTranslate, Title } from 'react-admin';
import axios from 'axios';
import API_ROUTES from '../routes/routes'; 
import useAdmin from '../hooks/useAdmin';

const Segments = () => {
    const [segments, setSegments] = useState<Segment[]>([]);
    const translate = useTranslate();

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}${API_ROUTES.segments}`)
            .then(response => {
                setSegments(response.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, []);
    useAdmin();
    return (
        <Card sx={{ mt: 2 }}>
            <Title title={translate('resources.segments.name', { smart_count: 2 })} />
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>{translate('resources.segments.fields.name')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {segments.map(segment => (
                        <TableRow key={segment.segmentid.toString()}>

                            <TableCell>{segment.segmentname}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
};

export default Segments;
